import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/macpro/Docs/projects/itzsrv-weblog-prod/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner"></AuthorBanner>
    <h4 {...{
      "id": "biography"
    }}>{`Biography`}</h4>
    <p>{`I'm currently associated as `}<strong parentName="p">{`Senior Software Engineer`}</strong>{` with `}<strong parentName="p">{`Wipro Limited`}</strong>{`, an Indian multinational corporation that provides information technology, consulting and business process services.`}</p>
    <p>{`I have worked on Java Web Components, Java Core, Spring, SpringBoot, Rest Web Services, Java Persistence API, Spring Security, Shell Scripting, Oracle WebLogic Application Server, WLST, Oracle Database and have good exposure to Cloud Technologies and Web Services.
I am well-versed with Front-End and as well as Back-End Development, aspiring to become a Full-Stack Developer.`}</p>
    <p>{`My area of interest includes Web Development, Cloud, Java Script and its Frameworks, fiddling around upcoming Technologies, Blogging and Open Source Contributions.`}</p>
    <hr></hr>
    <h4 {...{
      "id": "education"
    }}>{`Education`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Bachelor of Technology, 2016`}<br parentName="p"></br>{`
`}{`U.I.E.T. Kanpur University`}<br parentName="p"></br>{`
`}<em parentName="p">{`Computer Science`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Post-Graduation Diploma, 2017`}<br parentName="p"></br>{`
`}{`CDAC ACTS, Pune`}<br parentName="p"></br>{`
`}<em parentName="p">{`Advance Computing`}</em></p>
      </li>
    </ul>
    <hr></hr>
    <h4 {...{
      "id": "professional-experience"
    }}>{`Professional Experience`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`May 2022 - Present`}<br parentName="p"></br>{`
`}{`Senior Software Engineer at Wipro, Pune`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`April 2021 - May 2022`}<br parentName="p"></br>{`
`}{`Senior Software Engineer at TechMahindra, Pune`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`March 2017 - April 2021`}<br parentName="p"></br>{`
`}{`Software Engineer at Atos, Pune`}</p>
      </li>
    </ul>
    <hr></hr>
    <h4 {...{
      "id": "additional-experience-and-certifications"
    }}>{`Additional Experience and Certifications`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Certified Cloud Practitioner, 2021`}<br parentName="p"></br>{`
`}{`AWS`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
            "href": "https://www.credly.com/badges/0be6d79a-034e-4a3e-ab04-9199af2d71a6"
          }}>{`[Verify Badge Here]`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Certificate in Problem Solving, 2020`}<br parentName="p"></br>{`
`}{`HackerRank`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
            "href": "https://www.hackerrank.com/certificates/5c0b095f1ebd"
          }}>{`[Verify Certificate Here]`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Certified Professional Training in Linux System Administrator`}</em>{`, 2015`}<br parentName="p"></br>{`
`}{`CDAC Mohali, Chandigarh`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Certified Professional Training in Network Administrator`}</em>{`, 2015`}<br parentName="p"></br>{`
`}{`CDAC Mohali, Chandigarh`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Certified Ethical Hacking Expert CEHE Level 1.0`}</em>{`, 2014`}<br parentName="p"></br>{`
`}{`HBTI Kanpur`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><em parentName="p">{`Certificate in Java Programming Language using Java SE 6`}</em>{`, 2013`}<br parentName="p"></br>{`
`}{`Oracle`}</p>
      </li>
    </ul>
    <FeedItems filterByTags={['certificate']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      